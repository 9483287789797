<template>
  <div>
    <v-form ref="form">

      <v-card-text>
        <v-row>
          <v-col>
            <p class="mt-3 mb-0" style="color: red; font-size: 1.15em">Please note that extensions are temporarily not being cloned, and need to be copied manually to the new ad groups. 
              Sorry for the inconvenience.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="6">
            <div class="mt-2 mb-5">
              <h2 center>Source</h2><small>Slim blueprint or translation account</small>
            </div>

            <GoogleAccountAutoComplete 
              v-model="selectedSourceAccountID"
              @changed="sourceAccountChanged"
              @refreshing="accountsRefreshing"
              accountSet="blueprintTest"
            ></GoogleAccountAutoComplete>

            <GoogleCampaignAutoComplete 
              v-model="selectedSourceCampaign"
              :accountID="selectedSourceAccountID"
              @changed="sourceCampaignChanged"
              @refreshing="sourceCampaignsRefreshing" 
            ></GoogleCampaignAutoComplete>

            <v-autocomplete v-model="selectedSourceAdGroups" :items="sourceAdGroups" multiple clearable :loading="loadingAdGroups" item-text="name" item-value="ad_group_id" label="Ad groups"
              prepend-icon="category" :disabled="!selectedSourceCampaign" required return-object :rules="[v => !!(v && v.length) || 'Source ad groups are required']">
              <template v-slot:append-outer>
                <v-icon @click="selectedSourceAdGroups = sourceAdGroups">select_all</v-icon>
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-title @click="refreshSourceAdGroups()">Refresh ad groups</v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <v-tooltip>
                  <template v-slot:activator="{ on }">
                    <v-icon class="ml-1" v-on="on">help</v-icon>
                  </template>
                  <span>Select "Refresh ad groups" from the menu on the left in order to update the list with the latest ad groups from the Google API
                  </span>
                </v-tooltip>
              </template>
            </v-autocomplete>

          </v-col>

          <v-col md="6">
            <div class="mt-2 mb-5">
              <h2 center>Destination</h2><small>Geo account</small>
            </div>

            <GoogleAccountAutoComplete 
              v-model="selectedDestAccountID"
              @changed="destAccountChanged"
              @refreshing="accountsRefreshing"
            ></GoogleAccountAutoComplete>

            <GoogleCampaignAutoComplete 
              v-model="selectedDestCampaign"
              :accountID="selectedDestAccountID"
              @changed="destCampaignChanged"
            ></GoogleCampaignAutoComplete>
          </v-col>

        </v-row>

        <div class="mt-4 mb-2">
          <h2 center>Options</h2><small>Ad groups to be created in destination campaign</small>
        </div>

        <v-row align="center" class="mt-0 mb-0">
          <span class="ml-3 mt-5 mr-3 settings-label"><v-icon small class="mr-2">accessibility</v-icon>Genders</span>
          <div v-for="gender in genders" :key="gender">
            <v-checkbox v-model="selectedGenders" :label="gender" :value="gender" dense hide-details class="shrink mr-2"></v-checkbox>
          </div>
        </v-row>

        <v-row align="center" class="mt-0 mb-0">
          <span class="ml-3 mt-5 mr-3 settings-label"><v-icon small class="mr-2">hourglass_empty</v-icon>Age Groups</span>
          <div v-for="ageGroup in ageGroups" :key="ageGroup">
            <v-checkbox v-model="selectedAgeGroups" :label="ageGroup" :value="ageGroup" dense hide-details class="shrink mr-2"></v-checkbox>
          </div>
        </v-row>

        <v-row align="center" class="mt-0 mb-2">
          <span class="ml-3 mt-5 mr-3 settings-label"><v-icon small class="mr-2">content_copy</v-icon>Match types</span>
          <div v-for="matchType in matchTypes" :key="matchType">
            <v-checkbox v-model="selectedMatchTypes" :label="matchType" :value="matchType" dense hide-details class="shrink mr-2"></v-checkbox>
          </div>
        </v-row>

      </v-card-text>

      <v-card-actions class="pl-5 pb-5">
        <v-btn color="primary" :loading="running" @click="multiply()">Multiply</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showCompleted">Completed</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-form>

    <v-card-text>
      <SystemJobsTable :headers="jobHeaders" :jobType="jobType" :updateKey="progressTableKey"></SystemJobsTable>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'CloningToolMultiplyAdGroups',

  data () {
    return {
      sourceAdGroups: [],
      loadingAdGroups: false,
      lsSourceAccount: 'cloningTool_selectedSourceAccount',
      lsDestAccount: 'cloningTool_selectedDestAccount',
      lsOptions: 'cloningTool_options',
      multiplyAdGroupEndpoint: '/a/google/multiply-ad-groups',
      selectedSourceAccountID: 0,
      selectedDestAccountID: 0,
      selectedSourceCampaign: null,
      selectedSourceAdGroups: [],
      selectedDestCampaign: null,
      genders: ['Male', 'Female', 'Unknown'],
      ageGroups: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+', 'Unknown'],
      matchTypes: ['Broad', 'Exact', 'Phrase'],
      selectedGenders: [],
      selectedAgeGroups: [],
      selectedMatchTypes: [],
      running: false,
      showCompleted: false,
      progressTableKey: 'init',
      jobType: 'Adwords - Multiply Ad Groups',
      jobHeaders: [
        { text: 'User', value: 'user_name' },
        { text: 'Source', value: 'text1' },
        { text: 'Destination', value: 'text2' },
        { text: 'Ad group', value: 'text3' },
        { text: '# new ad groups', value: 'text4' },
        { text: 'Status', value: 'status' },
        { text: 'Entry at', value: 'entry_at_time' },
        { text: 'Completed at', value: 'completed_at_time' },
        { text: 'External message', value: 'external_message' },
      ],
    }
  },

  computed: {
    accounts () {
      return this.$store.state.google.accounts
    },

    refreshSourceAdGroupsEndpoint () {
      return '/a/google/accounts/' + this.selectedSourceAccountID + '/refresh-ad-groups'
    },

    sourceAdGroupDataEndpoint () {
      return '/a/google/campaigns/' + this.selectedSourceCampaign.campaign_id + '/ad-groups?xsort=name&xfields=ad_group_id,name&name=~female_18-24_exact&xper_page=5000'
    }
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    GoogleCampaignAutoComplete: () => import('@/components/autocompletes/GoogleCampaignAutoComplete.vue'),
    SystemJobsTable: () => import('@/components/tables/SystemJobsTable.vue')
  },

  methods: {
    accountsRefreshing () {
      this.selectedSourceAccountID = 0
      this.selectedDestAccountID = 0

      this.selectedSourceCampaign = null
      this.selectedDestCampaign = null

      this.selectedSourceAdGroups = []
    },

    destAccountChanged: function () {
      localStorage.setItem(this.lsDestAccount, JSON.stringify(this.selectedDestAccountID))
    },
    
    destCampaignChanged: function (selectedID) {
    },

    fetchSourceAdGroupData: function () {
      this.showAPIError = false
      this.sourceAdGroups = []
      this.selectedSourceAdGroups = []
      this.loadingAdGroups = true

      var myURL = this.sourceAdGroupDataEndpoint

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.sourceAdGroups = resp.data.data
      } else {
           this.sourceAdGroups = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingAdGroups = false)
    },

    multiply: function () {
      // make sure all selects have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.running = true

      // save options in local storage
      var optionsObj = {
        'selectedGenders': this.selectedGenders,
        'selectedAgeGroups': this.selectedAgeGroups,
        'selectedMatchTypes': this.selectedMatchTypes,
      }
      localStorage.setItem(this.lsOptions, JSON.stringify(optionsObj))

      var adGroupIDs = this.selectedSourceAdGroups.map(ele => ele.ad_group_id)
      var adGroupNames = this.selectedSourceAdGroups.map(ele => ele.name)

      // gather params and send to API
      var multiplyObj = {
        'sourceAccountID': this.selectedSourceAccountID,
        'sourceAccountName': this.accounts.filter(el => el.account_id == this.selectedSourceAccountID).map(val => val.name)[0],
        'sourceCampaignID': this.selectedSourceCampaign.campaign_id,
        'sourceCampaignName': this.selectedSourceCampaign.name,
        'sourceAdGroupIDs': adGroupIDs,
        'sourceAdGroupNames': adGroupNames,
        'destAccountID': this.selectedDestAccountID,
        'destAccountName': this.accounts.filter(el => el.account_id == this.selectedDestAccountID).map(val => val.name)[0],
        'destCampaignID': this.selectedDestCampaign.campaign_id,
        'destCampaignName': this.selectedDestCampaign.name,
        'selectedGenders': this.selectedGenders,
        'selectedAgeGroups': this.selectedAgeGroups,
        'selectedMatchTypes': this.selectedMatchTypes,
      }
      var body = JSON.stringify(multiplyObj)
      //console.log(body)

      this.$http.post(this.multiplyAdGroupEndpoint, body).then(resp => {
        this.progressTableKey = this.$dateTime.now().toString()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.multiplyAdGroupEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.running = false)
    },

    refreshSourceAdGroups () {
      this.loadingAdGroups = true

      this.selectedSourceAdGroups = []

      this.$http.post(this.refreshSourceAdGroupsEndpoint).then(resp => {
        this.fetchSourceAdGroupData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshSourceAdGroupsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingAdGroups = false)
    },

    sourceAccountChanged: function () {
      localStorage.setItem(this.lsSourceAccount, JSON.stringify(this.selectedSourceAccountID))
    },

    sourceCampaignChanged: function (selectedID) {

      // load ad groups
      this.fetchSourceAdGroupData()
    },

    sourceCampaignsRefreshing () {
      this.selectedSourceCampaign = null
      this.selectedSourceAdGroups = []
    },
  },

  created: function () {
    // if present, use local accounts
    if (localStorage.getItem(this.lsSourceAccount)) {
      this.selectedSourceAccountID = JSON.parse(localStorage.getItem(this.lsSourceAccount))
      this.sourceAccountChanged()
    }
    if (localStorage.getItem(this.lsDestAccount)) {
      this.selectedDestAccountID = JSON.parse(localStorage.getItem(this.lsDestAccount))
      this.destAccountChanged()
    }

    // options: get local if present, else select all
    if (localStorage.getItem(this.lsOptions)) {
      var optionsObj = JSON.parse(localStorage.getItem(this.lsOptions))
      this.selectedGenders = optionsObj.selectedGenders
      this.selectedAgeGroups = optionsObj.selectedAgeGroups
      this.selectedMatchTypes = optionsObj.selectedMatchTypes
    } else {
      this.selectedGenders = this.genders
      this.selectedAgeGroups = this.ageGroups
      this.selectedMatchTypes = this.matchTypes
    }
},

}
</script>

<style scoped>

.settings-label {
  width: 115px;
}

.shrink >>> label {
  font-size: 14px;
  width: 45px;
}

</style>